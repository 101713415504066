import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import logo from './IVAZI_House.jpg';
import './App.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { HashLink as Link  } from 'react-router-hash-link';
import { Scrollchor } from 'react-scrollchor';

const pages = ['Services', 'About','Project', 'Contact Us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
    },
  },
});

  return (
    <BrowserRouter>
    <ThemeProvider theme={darkTheme}>
    <AppBar position="fixed" color="primary" >
      <Container color="primary">
        <Toolbar disableGutters >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'} }}
          >
          <Scrollchor to='#Home' animate={{duration: 1300}}>
            <img src={logo} className='ImgIcon' />
          </Scrollchor>
          </Typography>

          <Box color="primary" sx={{ flexGrow: 0, display: { xs: 'flex-end', md: 'none', order:1} }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}

            >
            <Scrollchor to='#Home' animate={{duration: 1300}}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ color: 'white', display: 'block' }}
            >
              Home
            </Button>
            </Scrollchor>
            <Scrollchor to='#Services' animate={{duration: 1300}}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ color: 'white', display: 'block' }}
                >
                  Services
                </Button>
            </Scrollchor>
            <Scrollchor to='#About' animate={{duration: 1300}}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ color: 'white', display: 'block' }}
              >
                About
              </Button>
              </Scrollchor>

              <Scrollchor to='#Contact' animate={{duration: 1300}}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ color: 'white', display: 'block' }}
              >
                Contact
              </Button>
              </Scrollchor>

            </Menu>
          </Box>
          <Box sx={{  display: { xs: 'none', md: 'flex'  } }}>
          <Scrollchor to='#Home' animate={{duration: 1300}}>
          <Button
            onClick={handleCloseNavMenu}
            sx={{ color: 'white', display: 'block' }}
          >
            Home
          </Button>
          </Scrollchor>
          <Scrollchor to='#Services' animate={{duration: 1300}}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ color: 'white', display: 'block' }}
              >
                Services
              </Button>
          </Scrollchor>
          <Scrollchor to='#About' animate={{duration: 1300}}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ color: 'white', display: 'block' }}
            >
              About
            </Button>
            </Scrollchor>

            <Scrollchor to='#Contact' animate={{duration: 1300}}>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ color: 'white', display: 'block' }}
            >
              Contact
            </Button>
            </Scrollchor>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1,  display: { xs: 'flex', md: 'none'   } }}
          >
          <Scrollchor to='#Home' animate={{duration: 1300}}>
            <img src={logo} className='ImgIcon' />
          </Scrollchor>
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
    {/*<Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/College" component={College} />
      <Route exact path="/About" component={About} />
      <Route exact path="/Personal" component={Personal} />
    </Switch>*/}
    </BrowserRouter>
  );
};
export default ResponsiveAppBar;
