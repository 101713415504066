import logo from './IVAZI_Master.jpg';
import './App.css';
import ResponsiveAppBar from './Routes'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { styled,  makeStyles } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
//import Item from '@mui/material/Item';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import  Divider from '@mui/material/Divider'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Input } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Icon from '@mui/material/Icon';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

/* add image
<ImageList sx={{ width: 1000, height: 600 }}  rowHeight={600}>
    <ImageListItem >
      <img src={logo} className='logoheader' sx={{ m: 100}}/>
      />
    </ImageListItem>
</ImageList>
*/
function App() {

  return (
    <div className="App">
    <section id="Home" className='HeaderImgsection'/>
    <Container>
    <ResponsiveAppBar />
    <div id="Services" />
    <Grid className="ServicesID">
      <Divider className="ServiceDivider" >
        <Chip className="serviceHeader" label="Services" variant="outlined" sx={{color:'black', borderColor:'black'}}/>
      </Divider>
    </Grid>
    <Grid container rowSpacing={5} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
      <Grid item xs={12} md={6}>
      <Card>
      <CardMedia
          component="img"
          height="280"
          image="Painting.jpg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Interior & Exterior Painting
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
          Interior and exterior painting can transform the look and feel of a living space. Interior painting creates a comfortable and appealing ambiance inside the house using different colors, textures, and finishes. Exterior painting protects the exterior surfaces of a building from environmental damage and enhances its curb appeal using special paints and coatings.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
        <CardMedia
            component="img"
            height="280"
            image="Flooring.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              Floors
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Flooring plays an essential role in home improvement, and the right flooring can enhance the aesthetics and functionality of a living space. Various types of flooring materials, such as hardwood, laminate, tile, vinyl, and carpet, offer different benefits in terms of durability, maintenance, and aesthetic appeal.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
        <CardMedia
            component="img"
            height="280"
            image="tempPic.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              Bathroom Remodels
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Bathroom remodels are a popular home improvement project that can increase the functionality and value of a home. They involve upgrading or changing the fixtures, tiles, cabinets, and lighting to create a more stylish and efficient space. Well-executed bathroom remodels can provide a relaxing and refreshing experience.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
        <CardMedia
            component="img"
            height="280"
            image="Kitchen.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h4" component="div">
              Kitchen Remodels
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Kitchen remodels are a popular home improvement project that can transform the heart of the home. They involve upgrading or changing the cabinets, countertops, appliances, and flooring to create a more functional and stylish space. Well-executed kitchen remodels can improve the overall value and appeal of a home.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
<div id="About" />
    <Grid  className="AboutID">
      <Divider className="AboutDivider">
        <Chip className="AboutHeader" label="About" variant="outlined" sx={{color:'black', borderColor:'black'}}/>
      </Divider>
    </Grid>
    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} >
      <Card>
        <CardContent>
          <Typography variant="h6" color="#615e5e">
Ivazi Construction is a family-owned business that takes pride in its work. With 15 years of experience, they have honed their skills in home improvement services, such as interior and exterior painting, flooring, bathroom remodels, and kitchen remodels. Ivazi Construction's aim is to provide affordable prices without sacrificing the quality of its work. Our commitment to delivering excellent results has earned them a loyal customer base who trust them for all their home improvement needs.
          </Typography>
        </CardContent>
      </Card>
      </Grid>
    </Grid>
    </Container>
  <div className="TestingContact">
  <Container className="ContactContainer">
  <div id="Contact" />
  <Grid  sx={{backgroundColor: "black"}}>
    <Grid className="ContactID" >
      <Divider className="ContactDivider" >
        <Chip className="ContactHeader" label="Contact" variant="outlined" sx={{color:'white', borderColor:'white'}}/>
      </Divider>
    </Grid>
    <Grid container rowSpacing={5} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid className="contactGrid" item xs={12} md={6}>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
            </ListItemAvatar>
              <ListItemText
                primary="Reach Us"
              />
            </ListItem>
            <Divider className="ListDivider" variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
              <PhoneIphoneIcon/>
              </ListItemAvatar>
              <ListItemText
                primary="Phone:"
                secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                      206-372-9425
                    </Typography>
                  }
              />
            </ListItem>
            <Divider className="ListDivider" variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
              <MailOutlineIcon/>
              </ListItemAvatar>
              <ListItemText
                primary="Email:"
                secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                    <a href = "mailto:Ivazi.construction@gmail.com">Ivazi.construction@gmail.com</a>
                    </Typography>
                  }
              />
            </ListItem>
            <Divider className="ListDivider" variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <InstagramIcon/>
              </ListItemAvatar>
              <ListItemText
                primary="Instagram:"
                secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="white"
                    >
                    <a href="https://www.instagram.com/ivaziconstruction/" target="_blank">Ivaziconstruction</a>
                    </Typography>
                  }
              />
            </ListItem>
            <Divider className="ListDivider" variant="inset" component="li" />
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: "black"}}>
            <CardContent>
            </CardContent>
            </Card>
          </Grid>
      </Grid>
    </Grid>
    </Container>
    </div>
    <footer></footer>
    </div>
  );
}

export default App;
